import { FC } from 'react'
import styled, { useTheme } from 'styled-components'
import { validFor } from '@/theme/validFor'
import { Asset, useAsset } from '@/providers/assetsProvider'
import {
  MediaContextProvider,
  Mobile,
  Tablet,
  Desktop
} from '@/components/atoms/media'
import { Routes } from '@/core/routes'
import Link from '@/components/atoms/link'
import { Box } from '@/components/atoms/Grid'
import Image from 'next/image'

type ToolbarLinkLogoProps = {
  consistentAcrossLayouts?: boolean
} & Rest
const ToolbarLinkLogo: FC<ToolbarLinkLogoProps> = ({
  consistentAcrossLayouts = false,
  ...rest
}) => {
  const { asset } = useAsset()
  const theme = useTheme()

  return (
    <InlineWrapper lockDimensions={consistentAcrossLayouts} {...rest}>
      <Link href={Routes.home}>
        {consistentAcrossLayouts === true ? (
          <Image
            src={asset(Asset.ErrorLogo, true)}
            width={theme.logo.size.desktop.width}
            height={theme.logo.size.desktop.height}
            data-cy="headerLogo"
            unoptimized
          />
        ) : (
          <MediaContextProvider>
            <Desktop>
              <Image
                src={asset(Asset.InvertedLogo, true)}
                width={theme.logo.size.desktop.width}
                height={theme.logo.size.desktop.height}
                data-cy="headerLogo"
                unoptimized
              />
            </Desktop>
            <Tablet>
              <Image
                src={asset(Asset.MobileMenuLogo, true)}
                width={theme.logo.size.tablet.width}
                height={theme.logo.size.tablet.height}
                data-cy="headerLogo"
                unoptimized
              />
            </Tablet>
            <Mobile>
              <Image
                src={asset(Asset.Logo, true)}
                width={theme.logo.size.mobile.width}
                height={theme.logo.size.mobile.height}
                unoptimized
              />
            </Mobile>
          </MediaContextProvider>
        )}
      </Link>
    </InlineWrapper>
  )
}

export default ToolbarLinkLogo

const InlineWrapper = styled(Box)`
  height: ${({ theme }) => theme.logo.size.desktop.height}px;
  width: ${({ theme }) => theme.logo.size.desktop.width}px;
  ${validFor.tablet` 
    height: ${({ theme }) => theme.logo.size.tablet.height}px;
    width: ${({ theme }) => theme.logo.size.tablet.width}px;
	`};
  ${validFor.mobile` 
    height: ${({ lockDimensions, theme }) =>
      lockDimensions
        ? theme.logo.size.desktop.height
        : theme.logo.size.mobile.height}px;
    width: ${({ lockDimensions, theme }) =>
      lockDimensions
        ? theme.logo.size.desktop.width
        : theme.logo.size.mobile.width}px;
	`};
  align-self: center;
  &:hover {
    cursor: pointer;
  }
`
