import {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect
} from 'react'
import 'swiper/swiper.min.css'
import MobileCategoriesMenu from './mobileCategoriesMenu'
import MobileSubcategoriesMenu, {
  MobileSubcategoriesMenuActions
} from './mobileSubcategoriesMenu'
import SlideMenu, { SlideMenuActions } from '@/components/organisms/slideMenu'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { Asset, useAsset } from '@/providers/assetsProvider'
import {
  HomeBanner,
  Market,
  NavigationItem,
  PremiumManufacturer
} from '@/network/graphql.g'
import { useTheme } from 'styled-components'

type MainSlideMenuProps = {
  navigationItems: NavigationItem[]
  otherMenuItems: NavigationItem[]
  onDidClose?: () => void
  markets: Market[]
  premiumManufacturers: PremiumManufacturer[]
}

export type MainSlideMenuActions = {
  open: () => void
}

// TODO: refactor to use swiper-react
const MainSlideMenu = forwardRef<MainSlideMenuActions, MainSlideMenuProps>(
  ({ navigationItems, otherMenuItems, markets, premiumManufacturers }, ref) => {
    const { asset } = useAsset()
    const subcategoriesEl = useRef<MobileSubcategoriesMenuActions>(null)
    const slideMenu = useRef<SlideMenuActions>(null)
    const [showSlideMenu, setShowSlideMenu] = useState<boolean>(false)
    const theme = useTheme()
    useImperativeHandle(ref, () => ({
      open: () => setShowSlideMenu(true)
    }))
    const router = useRouter()
    useEffect(() => {
      setShowSlideMenu(false)
    }, [router])
    const handleClickCategoryItem = (
      id: string,
      subCategories: NavigationItem[],
      banners: HomeBanner[]
    ) => {
      if (subCategories?.length > 0) {
        subcategoriesEl.current.setSubcategories(
          id,
          subCategories,
          banners,
          premiumManufacturers.filter((item) => item.category === id)
        )
        slideMenu.current.slideNext()
      }
    }
    if (!showSlideMenu) {
      return null
    }

    return (
      <SlideMenu
        onDidClose={() => setShowSlideMenu(false)}
        ref={slideMenu}
        defaultNavigationBartTitle={
          <Image
            src={asset(Asset.MobileMenuLogo, true)}
            width={theme.logoWidth}
            height={theme.logoHeigth}
            data-cy="mobileMenuLogo"
            unoptimized
          />
        }
        pages={[
          {
            page: (
              <MobileCategoriesMenu
                navigationItems={navigationItems}
                otherMenuItems={otherMenuItems}
                onClick={handleClickCategoryItem}
                markets={markets}
              />
            )
          },
          {
            // * EMPTY TEMPLATE (ALL ACTION IN ONCLICK EVENT ON mobileCategoriesMenu)
            page: (
              <MobileSubcategoriesMenu
                categoryId={navigationItems[0].id}
                ref={subcategoriesEl}
                navigationItems={navigationItems[0]?.children || []}
                banners={navigationItems[0]?.homeBanners}
                premiumManufacturers={premiumManufacturers.filter(
                  (item) => item.category === navigationItems[0].categoryId
                )}
              />
            )
          }
        ]}
      />
    )
  }
)

export default MainSlideMenu
